import React from "react";
import logo from "../Assets/Logo/logo.webp";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <nav className="navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Naturals Logo" className="logo" />
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Header;
