import React, { useEffect } from "react";
import logo from "../Assets/Logo/logo.webp";
import ReactPlayer from "react-player";
import thankyouImage from "../Assets/3.webp";
const Thankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    //window.fbq("track", "Lead");
  }, []);

  return (
    <>
      <div className="container">
        <section className="">
          <div className="alignment">
            <img src={logo} alt="Naturals" className="logo" />
          </div>
        </section>
        <section className="mt-2">
          <div className="alignment">
            <h6 className="thankstitle">"CONGRATULATIONS"</h6>
            <p className="thankstitle1">
              You are an&nbsp;
              <span className="freecall">ACTION TAKER</span>
              <br />
              <span className="thankssubtitle">(Fortune Favours The Bold)</span>
            </p>
            <p className="thankstitle1">
              You Are One Step Away From Changing Your Life Forever
            </p>
            <div className="my-2">
            <img
                style={{width:"80%"}}
                src={thankyouImage}
                alt="Naturals"
                className="bannerimage"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Thankyou;
