import React, { useEffect } from "react";
import logo from "../Assets/Logo/logo.webp";
import { InlineWidget } from "react-calendly";

const Apply = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    //window.fbq("track", "Lead");
  }, []);

  return (
    <>
      <section>
        <div className="container">
          <div className="">
          {/* <img src={logo} alt="Naturals" className="calenderlogo" /> */}
            <div className="">
              <InlineWidget url="https://calendly.com/d/5h8-4rz-7d9" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Apply;
