import React, { useEffect, useState } from "react";
import Header from "./Header";
import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom/dist";
import bannerimage from "../Assets/Bannerimage.webp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Footer from "./Footer";
import DupModal from "../DupModal";

const Optin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    //window.fbq("track", "Lead");
  }, []);

  const [addModel, setAddModel] = useState(false);
  const [dupModel, setdupModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [create, SetCreate] = useState({});
  const [validation, setValidation] = useState({});

  const currentDate = new Date();
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!create.Name) {
      validdateData.Name = "Name is Required";
      Status = false;
    }
    if (!create.Email) {
      validdateData.Email = "Email is Required";
      Status = false;
    }
    if (!create.Number) {
      validdateData.Number = "Number is Required";
      Status = false;
    } else if (create.Number.length !== 12) {
      validdateData.Number = "Invalid Number";
      Status = false;
    }
    if (!create.Location) {
      validdateData.Location = "Location is Required";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const setData = (e, key) => {
    SetCreate({ ...create, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let inputValue = {
      "Name": create.Name,
      "Phone Number": create.Number,
      "Email Id": create.Email,
      "City": create.Location,
      "Date": currentDate.toDateString(),
    };
    if (CheckValidation()) {
      try {
        var form_data = new FormData();
        for (var key in inputValue) {
          form_data.append(key, inputValue[key]);
        }
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbzPczximTXWb0bhNaX-i_LargIIQo9iXWeWDl7DXJGgS28LoFBv_pfdaxYFIdSRavIdgw/exec", // Replace with the URL from the deployed web app
          {
            method: "POST",
            body: form_data,
          }
        );
        navigate("./Masterclass");
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setButtonDisabled(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="">
          <h1 className="subsubtitle">
            Looking for a  <span className="parahighlight">Franchise</span> Business Opportunity?
          </h1>
          <h1 className="title">
            Become An <span className="parahighlight">Edupreneur</span> with India’s <span className="parahighlight"> #1</span> Salon Chain!
          </h1>
          <h1 className="subsubtitle pt-3">
            Be Your <span className="parahighlight">Own Boss</span> with<span className="parahighlight"> Naturals Beauty Academy </span> with <span className="parahighlight">unparalleled support</span> & <span className="parahighlight">training</span> from the brand. <br></br>(No Prior Business Experience Required)
          </h1>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={bannerimage}
                alt="Naturals"
                className="bannerimage"
                style={{ cursor: "pointer" }}
                onClick={() => setAddModel(true)}
              />
            </div>
            <div className="col-lg-6">
              <p className="para">
                What you will learn from this <span className="parahighlight">MASTERCLASS</span>
              </p>
              <p className="subpara">
                <span className="icon">✔</span> Opportunities in the Beauty Education Industry<br />
                <span className="icon">✔</span> How to launch your Beauty Academy<br />
                {/* <span className="icon">✔</span> Becoming a Good Franchise Partner<br /> */}
                <span className="icon">✔</span> Choosing the Right Beauty Academy Business<br />
                <span className="icon">✔</span> How to be a part of India’s Largest Family Salon Chain<br />
              </p>
              <div className="alignment">
                <button className="button" onClick={() => setAddModel(true)}>
                  Watch Now For FREE
                </button>
                {/* <div className="row">
                  <div className="col-lg-7 mx-auto ms-auto">
                    <div className="row mt-3">
                      <div className="col-lg-2 col-2"></div>
                      <div className="col-lg-4 col-4 p-0">
                        <div className="alignment">
                          <img src={star} alt="Star" className="starimage" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-4 p-0">
                        <img src={trust} alt="Star" className="trustimage" />
                      </div>
                      <div className="col-lg-2 col-2"></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <p className="subpara mt-1" style={{paddingLeft:"70px"}}>
        <span className="icon">✔</span> Become A Successful Entrepreneur with India’s Largest Family Salon Chain<br />
        <span className="icon">✔</span> 23 years in Business | 770+ Salons | 20 States | 450+ Women Entrepreneurs<br />
        <span className="icon">✔</span> Learn how to be a part of the beauty and wellness revolution while making a business for yourself<br />
      </p> */}
      {/*  addModel */}
      <CustomModal
        open={addModel}
        onClickOutside={() => {
          setAddModel(false);
        }}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="modal-content  m-auto">
            <div className=" text-center mt-5">
              <h6 className="titlemodel">Watch Now For FREE!!!</h6>
              <p className="paramodel">
                Enter your info, start watching the
                <span className="parahighlighttext"> MASTERCLASS </span>
                immediately
              </p>
            </div>
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container">
                    <div className="mt-4">
                      <input
                        type="text"
                        className="inputbox"
                        placeholder="Enter Your Full Name*"
                        defaultValue={create.Name}
                        onChange={(e) => {
                          setData(e.target.value, "Name");
                        }}
                      />
                      {validation.Name && (
                        <p className="validationmsg">{validation.Name}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <PhoneInput
                        country={"in"}
                        placeholder="Enter Your Mobile Number"
                        autoComplete="off"
                        onChange={(e) => {
                          setData(e, "Number");
                        }}
                        defaultValue={create.Number}
                      />
                      {validation.Number && (
                        <p className="validationmsg">{validation.Number}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <input
                        type="text"
                        className="inputbox"
                        placeholder="Enter Your Email Address*"
                        defaultValue={create.Email}
                        onChange={(e) => {
                          setData(e.target.value, "Email");
                        }}
                      />
                      {validation.Email && (
                        <p className="validationmsg">{validation.Email}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <input
                        type="text"
                        className="inputbox"
                        placeholder="Enter Your City*"
                        defaultValue={create.Location}
                        onChange={(e) => {
                          setData(e.target.value, "Location");
                        }}
                      />
                      {validation.Location && (
                        <p className="validationmsg">{validation.Location}</p>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="modelbutton"
                      disabled={buttonDisabled || loading}
                    >
                      Watch Now For FREE!
                      {loading ? (
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <div>
                <button
                  type="button"
                  className="btn mx-2 submit-btn"
                  onClick={() => {
                    setAddModel(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
      <DupModal
        open={dupModel}
        onClickOutside={() => {
          setdupModel(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className=" text-center mt-5">
            <h6 className="titlemodel">Hi Again !!!</h6>
            <p className="paramodel">
              We have found that your contact information already available in our system.
            </p>
          </div>
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container">
                  <button
                    type="submit"
                    className="modelbutton"
                    onClick={() => {
                      setdupModel(false);
                    }}
                  >
                    Okay!!! Sure
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DupModal>
      <Footer />
    </>
  );
};

export default Optin;
